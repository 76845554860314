import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _11e32198 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _01000930 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _647f7267 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _146cb30a = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _c07a8f56 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _6b7a101b = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4124cfea = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _ad778ab2 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _26db07d3 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _aa4a33a6 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _59ceee78 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _49baedf5 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _aaf8e4e6 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _475a7579 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _100f1158 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _ec376d44 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _98caa1a2 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _11e32198,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _01000930,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _647f7267,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _146cb30a,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _c07a8f56,
    name: "receipt"
  }, {
    path: "/search",
    component: _6b7a101b,
    name: "search"
  }, {
    path: "/search-booking",
    component: _4124cfea,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _ad778ab2,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _26db07d3,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _aa4a33a6,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _59ceee78,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _49baedf5,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _aaf8e4e6,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _475a7579,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _100f1158,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _ec376d44,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _98caa1a2,
    name: "index"
  }, {
    path: "/dedicated",
    component: _98caa1a2,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _6b7a101b,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _100f1158,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _49baedf5,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _c07a8f56,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
